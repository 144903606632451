import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { imgUrl } from "@/utils/urlManage";
export default defineComponent({
  props: {
    partnerList: {
      default: []
    },
    subtitle: {
      default: String
    },
    title: {
      default: String
    }
  },

  setup(props) {
    const toggle = ref();

    const _this = reactive({
      active: 0
    });

    const methods = {
      changItem(index) {
        _this.active = index;
      },

      clickLeft() {
        if (_this.active == 0) {
          _this.active = props.partnerList.length - 1;
        } else {
          _this.active--;
        }
      },

      clickRight() {
        if (props.partnerList.length <= _this.active + 1) {
          _this.active = 0;
        } else {
          _this.active++;
        }
      }

    };
    onMounted(() => {});
    return {
      imgUrl,
      ...toRefs(_this),
      ...methods,
      toggle
    };
  }

});