import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import Solution from './components/solution.vue';
import SwiperCom from './components/swiperCom.vue';
import ProductCenter from './components/productCenter.vue';
import PressCenter from './components/pressCenter.vue';
import Partners from './components/partners.vue';
import ContactUs from './components/contactUs.vue';
import { ElMessage } from 'element-plus';
import { getBanner, getProductBar, getResolution, getLatestNews, getPartnerList, getDisplayArea, getOrgCodeByConfig } from '@/http/home';
import ProductDisplay from '@/components/productDisplay.vue';
import { splitArray } from '@/utils/utils';
export default defineComponent({
  components: {
    SwiperCom,
    Solution,
    ProductCenter,
    PressCenter,
    Partners,
    ContactUs,
    ProductDisplay
  },

  setup() {
    const data = reactive({
      // 轮播图数据
      swiperList: [],
      // 产品中心数据
      productBarList: [],
      // 解决方案数据
      resolutionBarList: [],
      // 企业动态数据
      companyNews: [],
      // 行业新闻数据
      industryNews: [],
      // 合作伙伴列表
      partnerList: [],
      module2Title: ''
    });

    const _this = reactive({
      orgCode: sessionStorage.getItem('orgCode') || ''
    });

    const methods = {
      // 获取轮播图数据
      getBannerMethod() {
        getBanner({
          'orgCode': _this.orgCode
        }).then(res => {
          if (res.data.code == 200) {
            data.swiperList = res.data.data;
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      // 获取产品中心数据
      getProductBarMethod() {
        getProductBar().then(res => {
          if (res.data.code == 200) {
            if (res.data.data.length > 4) {
              data.productBarList = res.data.data.splice(0, 4);
            } else {
              data.productBarList = res.data.data;
            }
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      // 获取解决方案数据
      getResolutionMethod() {
        getResolution({
          'orgCode': _this.orgCode
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data.length > 5) {
              data.resolutionBarList = res.data.data.splice(0, 5);
            } else {
              data.resolutionBarList = res.data.data;
            }
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      // 获取展示区信息
      async getDisplayAreaMethod() {
        const res = await getDisplayArea({
          'orgCode': _this.orgCode
        });

        if (res.data.code == 200) {
          var _res$data$data$displa, _res$data$data$displa2;

          if (((_res$data$data$displa = res.data.data.displayModule1Infos) === null || _res$data$data$displa === void 0 ? void 0 : _res$data$data$displa.length) > 4) {
            data.productBarList = res.data.data.displayModule1Infos.splice(0, 4);
          } else {
            data.productBarList = res.data.data.displayModule1Infos;
          }

          if (((_res$data$data$displa2 = res.data.data.displayModule2Infos) === null || _res$data$data$displa2 === void 0 ? void 0 : _res$data$data$displa2.length) > 5) {
            data.resolutionBarList = res.data.data.displayModule2Infos.splice(0, 5);
          } else {
            data.resolutionBarList = res.data.data.displayModule2Infos;
          }

          data.module2Title = res.data.data.module2Title;
        } else {
          ElMessage.error(res.statusText);
        }
      },

      // 获取新闻动态方法
      getLatestNewsMthod() {
        getLatestNews().then(res => {
          if (res.data.code == 200) {
            data.companyNews = res.data.data.category1.splice(0, 3);
            data.industryNews = res.data.data.category2.splice(0, 3);
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      // 合作伙伴全部数据
      getPartnerListMethod() {
        getPartnerList({
          'orgCode': _this.orgCode
        }).then(res => {
          if (res.data.code == 200) {
            data.partnerList = splitArray(res.data.data, 21); // console.log(data.partnerList);
          } else {
            ElMessage.error(res.statusText);
          }
        });
      }

    };
    onMounted(async () => {
      if (_this.orgCode == "") {
        const res = await getOrgCodeByConfig({
          siteAddress: window.location.origin
        });

        if (res.data.code == 200) {
          sessionStorage.setItem("orgCode", res.data.data);
          _this.orgCode = res.data.data;
          methods.getBannerMethod();
          methods.getDisplayAreaMethod(); // 展示区
          // methods.getLatestNewsMthod()

          methods.getPartnerListMethod();
        }
      } else {
        methods.getBannerMethod();
        methods.getDisplayAreaMethod(); // 展示区
        // methods.getLatestNewsMthod()

        methods.getPartnerListMethod();
      } // methods.getProductBarMethod()
      // methods.getResolutionMethod()

    });
    return { ...toRefs(data)
    };
  }

});