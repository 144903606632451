import { defineComponent } from "vue";
import { basicMixin } from "@/mixin/basicMixin";
import { imgUrl } from "@/utils/urlManage";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  mixins: [basicMixin],
  props: {
    productBarList: {
      default: []
    }
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const methods = {
      goProduct(item) {
        router.push({
          path: `/columnDisplay`,
          query: {
            code: item.code,
            partCode: item.partCode
          }
        });
      }

    };
    return {
      imgUrl,
      ...methods,
      router
    };
  }

});