import { defineComponent, reactive, onMounted, toRefs } from "vue";
import { basicMixin } from "@/mixin/basicMixin";
import { getPartPage } from "@/http/home";
import { useRouter } from "vue-router";
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const router = useRouter();

    const _this = reactive({
      flag: false,
      name: "",
      subtitle: ""
    });

    const methods = {
      // 获取栏目列表
      getPartPageList() {
        getPartPage({
          orgCode: sessionStorage.getItem("orgCode") || "",
          listCode: 3,
          location: 1
        }).then(async res => {
          if (res.data.code == 200) {
            res.data.data.forEach(ele => {
              if (ele.typeCode == "inbuilt") {
                if (ele.link == "contact") {
                  _this.flag = true;
                  _this.name = ele.name;
                  _this.subtitle = ele.subtitle;
                }
              }

              if (ele.children) {
                ele.children.forEach(element => {
                  if (element.typeCode == "inbuilt") {
                    if (element.link == "contact") {
                      _this.flag = true;
                      _this.name = element.name;
                      _this.subtitle = element.subtitle;
                    }
                  }
                });
              }
            });
          }
        });
      },

      toPath() {
        const {
          name,
          subtitle
        } = _this;
        router.push(`contactUs?onlyName=${name}&subtitle=${subtitle}`);
      }

    };
    onMounted(async () => {
      methods.getPartPageList();
    });
    return { ...methods,
      ...toRefs(_this)
    };
  }

});