import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-75386910"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "con"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SwiperCom = _resolveComponent("SwiperCom");

  const _component_ProductCenter = _resolveComponent("ProductCenter");

  const _component_Solution = _resolveComponent("Solution");

  const _component_PressCenter = _resolveComponent("PressCenter");

  const _component_Partners = _resolveComponent("Partners");

  const _component_ContactUs = _resolveComponent("ContactUs");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("main", null, [_createVNode(_component_SwiperCom, {
    swiperList: _ctx.swiperList
  }, null, 8, ["swiperList"]), _createVNode(_component_ProductCenter, {
    productBarList: _ctx.productBarList,
    title: _ctx.$t('home.productCenter.title')
  }, null, 8, ["productBarList", "title"]), _createVNode(_component_Solution, {
    resolutionBarList: _ctx.resolutionBarList,
    module2Title: _ctx.module2Title,
    title: _ctx.$t('home.solution.title')
  }, null, 8, ["resolutionBarList", "module2Title", "title"]), _createVNode(_component_PressCenter, {
    companyNews: _ctx.companyNews,
    industryNews: _ctx.industryNews,
    title: _ctx.$t('home.pressCenter.title')
  }, null, 8, ["companyNews", "industryNews", "title"]), _createVNode(_component_Partners, {
    partnerList: _ctx.partnerList,
    subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti dignissimos',
    title: _ctx.$t('home.partners.title')
  }, null, 8, ["partnerList", "subtitle", "title"])]), _createVNode(_component_ContactUs)]);
}