import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-53c95d9d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "call-box"
};
const _hoisted_2 = {
  class: "contact-us"
};
const _hoisted_3 = {
  class: "con"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  return _ctx.flag ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_ctx.$t("home.contactUs.title")), 1), _createVNode(_component_el_button, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.toPath())
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.contactUs.btnTxt")), 1)]),
    _: 1
  })])])])) : _createCommentVNode("", true);
}