import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { imgUrl } from "@/utils/urlManage";
import moment from "moment";
import { basicMixin } from "@/mixin/basicMixin";
import { useRouter } from "vue-router";
import { getLatestNews } from "@/http/about";
export default defineComponent({
  mixins: [basicMixin],
  props: {
    companyNews: {
      default: []
    },
    industryNews: {
      default: []
    },
    title: {
      default: String
    }
  },

  setup() {
    const router = useRouter();
    const data = reactive({
      num: 0,
      // 企业动态新闻数据
      IndustryInformationList: [],
      oldList: []
    });
    const methods = {
      goNews() {
        router.push({
          path: `/about`,
          query: {
            flag: "true",
            typeNum: data.num
          }
        });
      },

      /**
       * 切换企业动态、行业新闻
       * @param num 切换的索引值
       */
      changeNum(num) {
        data.IndustryInformationList = [];
        data.num = num;
        data.oldList.forEach(ele => {
          if (ele.typeCode == num) {
            data.IndustryInformationList.push(ele);
          }
        });
      },

      /**
       * 获取新闻列表
       */
      getEnterpriseDynamicMethod() {
        getLatestNews({
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            data.oldList = res.data.data;
            methods.changeNum(data.num);
          }
        });
      }

    };
    onMounted(() => {
      methods.getEnterpriseDynamicMethod();
    });
    return {
      imgUrl,
      moment,
      ...toRefs(data),
      ...methods
    };
  }

});