import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { Pagination, A11y, Autoplay, Navigation } from 'swiper'; //这里就是更新后的路径，以前是import 'swiper/css'

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { imgUrl } from "@/utils/urlManage";
import { getBanner } from '@/http/home';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { fileExt } from '@/utils/utils';
export default defineComponent({
  setup() {
    const data = reactive({
      // 轮播图数据
      swiperList: []
    });
    const divDomList = ref([]);

    const getCarou = el => {
      if (el) {
        divDomList.value.push(el);
      }
    };

    const _this = reactive({
      autoplay: true,
      cutTime: 5000,
      orgCode: sessionStorage.getItem('orgCode') || ''
    });

    const router = useRouter();
    const modules = [Pagination, A11y, Autoplay, Navigation];
    const methods = {
      // 获取轮播图数据
      getBannerMethod() {
        getBanner({
          'orgCode': _this.orgCode
        }).then(res => {
          if (res.data.code == 200) {
            data.swiperList = res.data.data;
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      goImg(url) {
        if (url.slice(0, 4) == 'http') {
          window.open(url, '_blank');
        } else {
          window.open('//' + url, '_blank');
        }
      },

      /**
       * 获取走马灯的索引
       * @param nowIndex 当前走马灯元素的索引值
       */
      getIndex(nowIndex) {
        // console.log(nowIndex);
        let type = fileExt(data.swiperList[nowIndex].fileUrl); // return
        // 只对视频元素做操作

        if (type == "png" || type == 'jpg' || type == 'gif' || type == "PNG" || type == 'JPG' || type == 'GIF') return;
        let myVideo = divDomList.value[nowIndex]; //用户可能在上个视频未播放完毕就切换

        myVideo.currentTime = 0; // 拿到视频的时间，并加上延迟10ms，避免切换的太突兀

        _this.cutTime = myVideo.duration * 1000 + 10; // 监听播放

        myVideo.addEventListener("play", () => {
          _this.autoplay = false;
        }); //监听暂停

        myVideo.addEventListener("pause", () => {
          _this.autoplay = true;
        }); //监听播放完毕

        myVideo.addEventListener("ended", () => {
          //时间归零
          _this.autoplay = true;
          myVideo.currentTime = 0;
          _this.cutTime = 5000;
        });
      }

    };
    onMounted(() => {
      methods.getBannerMethod();
    });
    return {
      modules,
      imgUrl,
      ...methods,
      ...toRefs(data),
      ...toRefs(_this),
      router,
      getCarou,
      divDomList,
      fileExt
    };
  }

});