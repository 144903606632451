import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-78b2675b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "solution"
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "solution-con"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "img-box"
};
const _hoisted_8 = {
  key: 0,
  class: "look-detail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.resolutionBarList.length != 0 ? (_openBlock(), _createElementBlock("b", _hoisted_3)) : _createCommentVNode("", true), _createTextVNode(_toDisplayString(_ctx.module2Title), 1), _ctx.resolutionBarList.length != 0 ? (_openBlock(), _createElementBlock("b", _hoisted_4)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resolutionBarList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "solution-box",
      onClick: $event => _ctx.goProduct(item),
      key: item.id
    }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
      class: "img-item",
      style: _normalizeStyle({
        background: `url(${_ctx.imgUrl(item.fileUrl)}) repeat center center/cover`
      })
    }, null, 4)]), _createElementVNode("p", null, _toDisplayString(item.name), 1)], 8, _hoisted_6);
  }), 128))])]), _ctx.resolutionBarList.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("span", {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.router.push({
      path: '/columnDisplay',
      query: {
        partCode: _ctx.resolutionBarList[0].partCode
      }
    }))
  }, "查看更多 >")])) : _createCommentVNode("", true)]);
}